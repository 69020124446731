<template>
	<div>
		<div class="grid-cols-3 gap-4">
			<headLeft class="col-span-2 h"/>
			<div class="as" v-show="$store.state.deviceType === 'mobile'"></div>
		</div>

		<div class="wrapper">
		<h1>About</h1>
		<p>
			Welcome to
			<strong>yoloplay.games!</strong>
		</p>
		<p>At yoloplay.games, we bring you the best in H5 gaming. Our platform aggregates a wide variety of HTML5 games that you can enjoy directly in your browser without the need for downloads or installations. Whether you're looking for action, puzzle, strategy, or casual games, we've got something for everyone. Our mission is to provide a seamless and enjoyable gaming experience for players of all ages.</p>
		<p>Join us on this exciting journey and discover new games every day!</p>
	</div>
	</div>

</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrapper {
	margin-top: rem(30);
	padding: rem(28) rem(30);
    color: #002b51;
    font-family: Roboto;
    font-style: normal;
    border-radius: rem(20);
    background: #FFF;
	box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.40);
	h1 {
		font-size: rem(40);
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	p {
		font-size: rem(24);
		font-weight: 400;
		line-height: rem(50); /* 208.333% */
		letter-spacing: rem(1);
	}
}
</style>